import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material';
import { useApp } from 'use/app';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useNavigate, useLocation } from 'react-router-dom';
import { SideBarItemProps } from 'types/app';

function SidebarItem({
  title,
  icon,
  path,
  disabled,
}: SideBarItemProps): JSX.Element {
  const { superfeelUser } = useSuperfeelUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { drawerOpen } = useApp();

  const currentPath = path(superfeelUser?.username);
  const isSelected =
    location.pathname === currentPath ||
    (title === 'Users' && location.pathname === '/') ||
    (title === superfeelUser?.username &&
      location.pathname === `/user/${superfeelUser?.username}`) ||
    (title === 'Data' && location.pathname.includes('/data/')) ||
    (title === 'Twin' && location.pathname.includes('/twin/')) ||
    (title === 'Logs' && location.pathname.includes('log')) ||
    (title === 'Voice' && location.pathname.includes('/voice/'));

  return (
    <Tooltip title={disabled ? 'Currently being replaced - Please ask engineering for help' : ''} placement='right'>
    <ListItem
      key={title}
      disablePadding
      sx={{ display: 'block' }}
    >
      <ListItemButton
        disabled={disabled}
        onClick={() => navigate(currentPath)}
        selected={isSelected}
        sx={{
          minHeight: 48,
          justifyContent: drawerOpen ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: drawerOpen ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={title}
          sx={{ opacity: drawerOpen ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
    </Tooltip>
  );
}

export default SidebarItem;
